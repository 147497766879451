.App-logo {
    width: 30vmin;
    pointer-events: none;
}

body {
    font-size: 0.8rem;
}

.form-control {
    font-size: 0.8rem;
}

.form-select {
    font-size: 0.8rem;
}

.btn {
    font-size: 0.8rem;
}

.table-btn {
    font-size: 0.7rem;
    padding-top: 0.2rem;
    padding-top: 0.2rem;
}

.table {
    padding: 0.1rem 0.5rem
}

.dropdown-menu {
    font-size: 0.8rem;
}

.form-check-input {
    font-size: 1.2rem;
}

.form-radio-input .form-check-input {
    font-size: 1rem;
}

a {
    color: #0366d6;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

/* React table */
.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.rt-tr-group {
    background: white;
}

    .rt-tr-group:nth-of-type(even) {
        background: grey;
    }

.disable-qr-reader {
    background: grey;
}

.rounded-logo {
    border-radius: 10px;
    background: white;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
}

.scan-overlay {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 50px solid transparent;
    position: absolute;
    width: 100%;
    height: 100%;
}

.footer {
    box-shadow: 0 -0.25rem .75rem rgba(0, 0, 0, .05);
    background-color: white;
    font-size: 10px;
    color: black;
    text-align: center;
    padding: 0.4rem 1rem;
    width: 100%;
    /* Sticky footer */
    height: 0px;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
}

.link {
    color: #1a6db3;
    cursor: pointer;
    text-decoration: none;
}

.react-draggable .modal-header, .cursor {
    cursor: move;
}