.dot {
    height: 25px;
    width: 25px;
    border: 3px solid #a1a1a1;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}
    .dot.connected {
        background-color: #b6ff00;
    }

    .dot.bad {
        background-color: #e41414;
    }

    .dot.unknown {
        background-color: #808080;
    }
